import React, { useState, useRef, useEffect } from "react"
import DatePicker, { registerLocale } from "react-datepicker"
import enGb from "date-fns/locale/en-GB"
import it from "date-fns/locale/it"
import { closedDateRanges } from "../../helpers/closedDates"

import Calendar from "../../svg/calendar-dropdown.svg"

import { datePlusDays, formatDate } from "../../helpers/dates"

import { format, add, setDefaultOptions, addDays, startOfDay } from "date-fns"

registerLocale("it", it)
registerLocale("en-gb", enGb)

const DateInput = ({
  dateRange,
  setDateRange,
  filterPage,
  bgClass,
  showIcon = false,
}) => {
  const language = process.env.GATSBY_SITE_LANGUAGE
  let initStartDate
  let initEndDate

  const toUTCDate = date => {
    return new Date(date.getTime() + date.getTimezoneOffset() * 60000)
  }

  const utcClosedDateRanges = closedDateRanges.map(range => ({
    start: toUTCDate(range.start),
    end: toUTCDate(range.end),
  }))

  // const tomorrow = startOfDay(addDays(new Date(), 1))
  // const minDate = toUTCDate(tomorrow)

  const minDate = toUTCDate(new Date("2025-06-01"))
  // if (!filterPage) {
  //   initStartDate = new Date()
  //   initEndDate = datePlusDays(initStartDate)
  // }

  const button = useRef()
  const [open, setOpen] = useState(false)

  const toggleCalendar = () => setOpen(!open)

  const closeCalendar = evt => {
    if (evt?.target === button.current || evt?.target.closest(".dateButton"))
      return

    setOpen(false)
  }

  const handleChange = dates => {
    const [start, end] = dates

    setDateRange({
      startDate: start,
      endDate: end,
    })

    if (end !== null) closeCalendar()
  }

  const handleKeydown = evt => {
    if (evt.key === "Escape") closeCalendar()
  }

  useEffect(() => {
    setDateRange({
      startDate: initStartDate,
      endDate: initEndDate,
    })
  }, [])

  return (
    <>
      <button
        type="button"
        className={`dateButton flex items-center w-full p-2 lg:mb-2 border ${
          bgClass || "bg-white"
        } border-blue-500 rounded-4 ${
          filterPage ? "text-13 sm:text-sm" : "text-sm"
        } text-blue-500 transition duration-300 hover:bg-blue-100`}
        onClick={toggleCalendar}
        ref={button}
      >
        {dateRange?.startDate && dateRange?.endDate ? (
          <>
            <span className="mt-2px">
              <span className="hidden sm:inline">From</span>
              <b className="px-1">
                {dateRange?.startDate ? formatDate(dateRange.startDate) : " "}
              </b>
              to
              <b className="px-1">
                {dateRange?.endDate ? formatDate(dateRange.endDate) : " "}
              </b>
            </span>
            <span className="ml-auto button-calendar">
              <span className="sr-only">Open calendar</span>
            </span>
          </>
        ) : (
          <div className="flex justify-between items-center w-full">
            <span className="m-0">
              {language == "en"
                ? `Enter your dates...`
                : `Inserisci le tue date`}
            </span>

            {showIcon && <Calendar className="h-5" />}
          </div>
        )}
      </button>
      <div
        className={`datepicker-wrapper absolute z-10 ${
          filterPage ? "top-0 mt-12 md:ml-10px" : ""
        }`}
        aria-hidden={!open}
      >
        <DatePicker
          selected={dateRange?.startDate}
          onChange={handleChange}
          onKeyDown={handleKeydown}
          onClickOutside={closeCalendar}
          startDate={dateRange?.startDate}
          endDate={dateRange?.endDate}
          locale={language === "en" ? `en-gb` : `it`}
          minDate={minDate}
          selectsRange
          inline
          autoFocus
          excludeDateIntervals={utcClosedDateRanges}
        />
      </div>
    </>
  )
}

export default DateInput
